import styled from "styled-components"
import {fuenteTitulos} from "../variables"

export const HeroContainer = styled.section`
    background: rgb(2,2,48);
    background: linear-gradient(0deg, rgba(2,2,48,1) 0%, rgba(0,31,84,1) 100%);
    min-height: 650px;
    & > div{
      min-height: 650px;
    }
`;

export const Info = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const ImageContainer = styled.div`
  width: 100%;

  @media (max-width: 500px){
    display: none;  
  }

`;

export const Margin = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${props => props.columns};
  padding: 4em 0;
  grid-gap: 9em;
  min-height: 650px;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 500px){
    padding: 4em 2em;
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.h1`
  text-transform: uppercase;
  font-family: ${fuenteTitulos};
  font-size: ${props => props.home ? '4rem' : '3rem'};
  font-weight: 900;
  display: inline;
  line-height: 1;
  margin-bottom: 0;

  @media (max-width: 500px){
    font-size: 2.5rem;
  }
`;