import React from 'react'
import {HeroContainer, Info, ImageContainer, Margin, Title} from '../styles/components/Hero'
import ImageBackground from './Background'
import Image from './Image'

const Hero = (props)=>{

    let {bg,children} = props
    
    return(
        <HeroContainer>
            {
                bg ? (
                    <>
                        <ImageBackground filename={bg}>
                            <Inside {...props}>{children}</Inside>
                        </ImageBackground>
                    </>
                ) : (
                    <Inside {...props}>{children}</Inside>
                    )
            }
            
        </HeroContainer>
    )
}

const Inside = ({title,img,children,Svg, columns = '1fr 2fr',home,animation}) =>{

    return(
        <Margin columns={columns}>
            <Info>
                <Title home={home} >{title}</Title>
                {children}
            </Info>
            {
                (Svg || img) && (

                    <ImageContainer>
                        {Svg && <Svg animation={animation}/>}
                        {img && <Image  filename={img}/>}
                    </ImageContainer>
                )
            }
            
        </Margin>
    )
}

export default Hero;